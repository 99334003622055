import moment from "moment";
import {
    formatNumber,
    getSalary,
    getTotalBill,
    getTotalBillDiscount,
    getTotalBillItems,
    getTotalBillVAT,
    getTotalBillWithoutVAT
} from "../../common/common";
import {premisesInfo} from "../../security/premises-info";
import sortBy from "lodash/sortBy";

export const getCSVData = (bills, hasReason, ships) => {


    let header = [
        "Ngày giao hàng",
        "Giờ nhận",
        "Mã đơn hàng",
        "Nội dung đơn hàng",
        "Họ và tên khách hàng",
        "Số điện thoại khách",
        "Địa chỉ nhận",
        "Giá trị đơn hàng chưa bao gồm thuế",
        "Cơ sở",
        "Hình thức thanh toán",
        "Chiết khấu",
        "Ship",
        "Florist 1",
        "Florist 2",
        "Florist 3",
        "Florist 4",
        "Sale 1",
        "Sale 2",
        "Sale 3",
        "Sale 4",
        'Ngày bán hàng',
        "Ghi chú",
        "Nội dung thiệp",
        "Tiền Hàng",
        "Tổng VAT",
        "Tổng Tiền",
        "Phí Ship",
        "Phụ Thu",
        "Phụ Cấp",
        "Người hưởng 1",
        "Người hưởng 2",
        "Người hưởng 3",
        "Người hưởng 4",
        "Số KM",
        "Lương SHIP"
    ];

    if (hasReason) {
        header.push("Lí do");
    }

    let csvData = [header];


    const generateBillItemsText = (items) => {
        let ret = "";
        for (let item of items) {
            ret += `${item.quantity} ${item.flowerType} ${item.name} ${item.sale ? `(${item.sale}%)` : ''}(${formatNumber(item.price)})\n`
        }
        return ret;
    };

    const premises = premisesInfo.getPremises();


    if (bills) {
        for (let bill of sortBy(bills, b => b.deliverTime)) {
            let ret = [];
            ret.push(moment(bill.deliverTime).format("DD/MM/YYYY"));
            ret.push(moment(bill.deliverTime).format("HH:mm"));
            ret.push(bill.bill_number);
            ret.push(generateBillItemsText(bill.items));
            ret.push(bill.customer?.customerName || "");
            ret.push(bill.customer?.customerPhone || "");
            ret.push(bill.to.receiverPlace || "");
            ret.push(getTotalBillWithoutVAT(bill));
            ret.push(premises.find(p => p._id == bill.premises_id)?.name);
            ret.push(bill.to.paymentType || "");
            ret.push(getTotalBillDiscount(bill));
            const shipTypes = [{value: 0, label: ""}, {value: 1, label: "NG"}, {value: 2, label: "ĐX"}, {value: 3, label: "ĐXNG"}];
            ret.push(bill.ships && bill.ships[0] ? `${bill.ships[0].username} ${bill.ships[0].shipType != undefined ? `(${shipTypes.find(s => s.value == bill.ships[0].shipType).label})` : ""}` : "");





            if (bill.florists) {
                ret.push(bill.florists[0] ? bill.florists[0].username : "");
                ret.push(bill.florists[1] ? bill.florists[1].username : "");
                ret.push(bill.florists[2] ? bill.florists[2].username : "");
                ret.push(bill.florists[3] ? bill.florists[3].username : "");

            } else {
                ret.push(bill.to.florist);
                ret.push("");
                ret.push("");
                ret.push("");
            }

            if (bill.sales) {
                ret.push(bill.sales[0] ? `${bill.sales[0].username}${bill.sales[0].isOnl ? "- onl": ""}` : "");
                ret.push(bill.sales[1] ? `${bill.sales[1].username}${bill.sales[1].isOnl ? "- onl": ""}` : "");
                ret.push(bill.sales[2] ? `${bill.sales[2].username}${bill.sales[2].isOnl ? "- onl": ""}` : "");
                ret.push(bill.sales[3] ? `${bill.sales[3].username}${bill.sales[3].isOnl ? "- onl": ""}` : "");

            } else {
                ret.push(bill.to.saleEmp);
                ret.push("");
                ret.push("");
                ret.push("");
            };



            ret.push(moment(bill.created).format("DD/MM/YYYY"));
            ret.push((bill.to.notes || "").replace(/\n/g));
            ret.push((bill.to.cardContent || "").replace(/\n/g));
            ret.push(getTotalBillItems(bill));
            ret.push(getTotalBillVAT(bill));
            ret.push(parseInt(getTotalBill(bill)) + parseInt(bill.to.shipMoney || 0) + parseInt(bill.surcharge || 0));
            ret.push(bill.to.shipMoney);
            ret.push(bill.surcharge);
            ret.push(bill.surcharge2);
            ret.push(bill.surchargeMember[0] ? bill.surchargeMember[0].username : "");
            ret.push(bill.surchargeMember[1] ? bill.surchargeMember[1].username : "");
            ret.push(bill.surchargeMember[2] ? bill.surchargeMember[2].username : "");
            ret.push(bill.surchargeMember[3] ? bill.surchargeMember[3].username : "");
            ret.push(bill.to.distance);
            ret.push(bill.ships[0] ? getSalary(ships.find(s => s.user_id == bill.ships[0].user_id), bill).money : 0);
            ret.push(bill.surchargeMember[3] ? bill.surchargeMember[3].username : "");

            if (hasReason) {
                ret.push(bill.reason)
            }

            csvData.push(ret);
        }
    }

    console.log(csvData);

    return csvData;
};
