import React, {Fragment} from "react";
import {Input} from "../../../components/input/input";
import {DatePicker} from "../../../components/date-picker/date-picker";
import {TimePicker} from "../../../components/time-picker/time-picker";
import {AutoCompleteNormal} from "../../../components/auto-complete/auto-complete-normal";
import {googleMapsApi} from "../../../api/google-maps-api";
import {premisesInfo} from "../../../security/premises-info";
import debounce from "lodash/debounce";
import {InputNumber} from "../../../components/input-number/input-number";
import {InputTag} from "../../../components/input-tag/input-tag";
import uniq from "lodash/uniq"
import {paymentTypes, viaTypes} from "../../../common/constance";
import {Select} from "../../../components/select/select";
import {Radio} from "../../../components/radio/radio";
import {securityFe} from "../../../security/security-fe";
import {PictureUpload} from "../../../components/picture-upload/picture-upload";
import {getTotalBill} from "../../../common/common";

export class BillInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            distance: null,
            selectedSale: null
        }
    }


    getDistance = debounce((to) => {
        try {
            googleMapsApi.getDistance({from: premisesInfo.getActivePremise().address, to}).then((distance) => {
                this.setState({distance});
                let {deliverTime, onChange, to, bill} = this.props;
                onChange({...to, distance: Math.ceil(distance.value / 1000)})
            }, () => {
                this.setState({error: true, distance: 0})
            })
        } catch (err) {
            this.setState({error: true})
        }
    }, 800);


    UNSAFE_componentWillReceiveProps(props) {
      if (!props.to.receiverPlace || props.to.receiverPlace == "") {
          this.setState({error: false, distance: null})
      }
    }

    render() {

        let {to, onChange, deliverTime, onChangeDeliverTime, bill, onChangeBill, sales, florists, ships, billGiftTypes, editMode} = this.props;
        let {error, distance, selectedSale} = this.state;


        let _paymentTypes = paymentTypes.slice(1);

        if (!bill.is_e_wallet && bill.customer.e_wallet > getTotalBill(bill) && new Date().getTime() < new Date(bill.customer.e_wallet_expire_date).getTime()) {
            _paymentTypes = [`E-Card`].concat(_paymentTypes);
        }

        const billSaleSelected = bill.sales.find(b => b.username == selectedSale);

        return (
            <Fragment>

                <div className="row">
                    <AutoCompleteNormal
                        topInfo={<a onClick={() => {
                            onChange({
                                ...to,
                                receiverName: bill.customer?.customerName,
                                receiverPhone: bill.customer?.customerPhone
                            })
                        }}>Giống người đặt</a>}
                        label="Người Nhận Hàng*"
                        className="col"
                        value={to.receiverName}
                        onSelect={(location) => {
                            onChange({...to, receiverName: location});
                        }}
                        onChange={(value) => {
                            onChange({...to, receiverName: value})
                        }}
                        displayAs={(location) => location}
                        defaultList={uniq(!bill.customerInfo ? [] : bill.customerInfo.locations.map(l => l.receiverName)).filter(l => l != null && l.length > 0)}
                    />

                    <AutoCompleteNormal
                        label="Số Điện Thoại Nhận*"
                        className="col"
                        value={to.receiverPhone}
                        onSelect={(location) => {
                            onChange({...to, receiverPhone: location});
                        }}
                        onChange={(value) => {
                            onChange({...to, receiverPhone: value})
                        }}
                        displayAs={(location) => location}
                        defaultList={uniq(!bill.customerInfo ? []
                            : bill.customerInfo.locations
                            .filter(l => l.receiverPhone != null && l.receiverPhone.length > 0 && l.receiverName && l.receiverName.indexOf(to.receiverName) > -1)
                            .map(l => l.receiverPhone))
                        }
                    />
                </div>



                <div className="row">
                    <DatePicker
                        className="col"
                        label="Ngày nhận hàng*"
                        value={new Date(deliverTime)}
                        onChange={(deliverTime) => onChangeDeliverTime(deliverTime)}
                    />

                    <TimePicker
                        className="col"
                        value={new Date(deliverTime)}
                        onChange={(deliverTime) => {
                            onChangeDeliverTime(deliverTime);
                        }}
                    />
                </div>

                <div className="row">
                    <AutoCompleteNormal
                        className="col"
                        label="Nơi Nhận*"
                        value={to.receiverPlace}
                        onSelect={(location) => {
                            onChange({...to, receiverPlace: location});
                        }}
                        onChange={(value) => {
                            onChange({...to, receiverPlace: value});
                        }}
                        onBlur={() => {}}
                        displayAs={(location) => location}
                        defaultList={
                            uniq(!bill.customerInfo ? []
                                : bill.customerInfo.locations
                                .filter(l => l.receiverPlace != null && l.receiverPlace.length > 0 && l.receiverName && l.receiverName.indexOf(to.receiverName) > -1)
                                .map(l => l.receiverPlace))}
                        info={error ? "Không tính được khoảng cách vui lòng tư điền" : ""}
                        topInfo={<a onClick={() => {
                            this.setState({error: false, distance: null});
                            this.getDistance(to.receiverPlace)
                        }}>Tính khoảng cách</a>}
                    />

                    <InputNumber
                        label="Phí Ship"
                        className="col"
                        value={to.shipMoney}
                        onChange={(value) => onChange({...to, shipMoney: value})}
                    />
                </div>

                <div className="row">
                    <InputNumber
                        label="Khoảng cách ship (KM)*"
                        className="col"
                        value={to.distance}
                        onChange={(value) => onChange({...to, distance: value})}
                    />

                    <div className="col">


                        <Select
                            label="Hình Thức Thanh Toán*"
                            onChange={(paymentType) => onChange({...to, paymentType})}
                            list={_paymentTypes}
                            value={to.paymentType}
                            disabled={editMode && to.paymentType == "E-Card"}
                        />

                        { to.paymentType == "Thẻ" && (
                            <Input
                                label="MCC"
                                value={to.mcc}
                                onChange={(e) => onChange({...to, mcc: e.target.value})}
                            />
                        )}
                    </div>

                </div>

                <div className="row">

                    <Select
                        label="Kênh Mua Hàng*"
                        value={to.buyerFrom}
                        onChange={(buyerFrom) => onChange({...to, buyerFrom})}
                        list={viaTypes}
                        className="col"
                    />

                    <Select
                        className="col"
                        label="Dịp tặng hoa*"
                        value={to.billGiftType}
                        onChange={(billGiftType) => onChange({...to, billGiftType})}
                        list={billGiftTypes.map(b => b.name).sort()}
                        allowSearch
                    />
                </div>

                <div className="row">
                    <div className="col tag-sale">
                        <InputTag
                            label="Sale"
                            tags={bill.sales}
                            onChange={(sales) => onChangeBill({...bill, sales})}
                            list={sales.map(s => ({...s, isSale: true})).concat(florists)}
                            onClick={(sale) => this.setState({selectedSale: sale})}
                        />

                        { billSaleSelected && (
                            <div className="bill-sale">
                                <b>{billSaleSelected.username}</b>
                                <Radio
                                    label="Online"
                                    value={billSaleSelected.isOnl}
                                    onChange={() => onChangeBill({...bill, sales: bill.sales.map(s => s.username == billSaleSelected.username ? ({...s, isOnl: true}) : s)})}
                                />

                                <Radio
                                    label="Offline"
                                    value={!billSaleSelected.isOnl}
                                    onChange={() => onChangeBill({...bill, sales: bill.sales.map(s => s.username == billSaleSelected.username ? ({...s, isOnl: false}) : s)})}
                                />
                            </div>
                        )}
                    </div>

                    <div className="col">
                        <InputTag
                            label="Florist"
                            tags={bill.florists}
                            onChange={(florists) => onChangeBill({...bill, florists})}
                            list={florists}
                        />
                    </div>
                </div>



                <div className="row">
                    <div className="col tag-sale">
                        <Select
                            label="Ship"
                            value={bill.ships[0]?.username}
                            onChange={(selectedShip) => onChangeBill({...bill, ships: [ships.find(s => s.username == selectedShip)]})}
                            list={ships.map(s => s.username)}
                            removeAble={securityFe.isHavePermission(["bill.remove-ship"])}
                            onRemove={() => onChangeBill({...bill, ships: []})}
                        />

                        {bill.ships.length > 0 && (
                            <div className="bill-sale">
                                <b>Loại Ship</b>

                                {[{value: 0, label: "Thường"}, {value: 1, label: "Ngoài Giờ"}, {value: 2, label: "Đơn Xa"}, {value: 3, label: "Đơn Xa và Ngoài Giờ"}].map((item, index) => (
                                    <Radio
                                        key={index}
                                        label={item.label}
                                        value={(bill.ships[0].shipType || 0) == item.value}
                                        onChange={() => onChangeBill({...bill, ships: [{...bill.ships[0], shipType: item.value}]})}
                                    />
                                ))}
                            </div>
                        )}
                    </div>

                    <Input
                        className="col"
                        label="Ghi Chú"
                        value={to.notes}
                        onChange={(e) => onChange({...to, notes: e.target.value})}
                    />

                </div>

                <div className="row">

                    <InputNumber
                        label="Phụ thu"
                        className="col"
                        value={bill.surcharge}
                        onChange={(value) => onChangeBill({...bill, surcharge: value})}
                    />

                    <InputTag
                        className="col"
                        label="Người hưởng"
                        tags={bill.surchargeMember || []}
                        onChange={(surchargeMember) => onChangeBill({...bill, surchargeMember})}
                        list={sales.map(s => ({...s, isSale: true})).concat(florists).concat(ships)}
                    />

                </div>
                <div className="row">
                <InputNumber
                        label="Phụ cấp"
                        className="col"
                        value={bill.surcharge2}
                        onChange={(value) => onChangeBill({...bill, surcharge2: value})}
                    />
                    <div></div>
                </div>        

                <Input
                    className="col"
                    label="Nội Dung Thiệp"
                    textArea
                    value={to.cardContent}
                    onChange={(e) => onChange({...to, cardContent: e.target.value})}
                />

                <PictureUpload
                    className="col"
                    label="Ảnh Sản Phẩm"
                    value={bill.image}
                    onChange={(image) => onChangeBill({...bill, image})}
                />
            </Fragment>
        );
    }
}
